const gridSystemPlugin = require('tailwind-utils/gridSystem.plugin');
const typographyPlugin = require('tailwind-utils/typography.plugin');
const { withBackgroundColorVariants } = require('tailwind-utils/withColorVariants');

const colorsConfig = require('./configs/tailwind.colors');
const typographyConfig = require('./configs/tailwind.typography');
const proseConfig = require('./configs/tailwind.prose');

const fontFamily = {
  primary: `var(--font-primary), Arial, sans-serif`,
  secondary: `var(--font-secondary), Arial, sans-serif`,
};

/** @type {import('tailwindcss').Config} */

module.exports = {
  theme: {
    extend: {
      fontFamily,
      colors: colorsConfig,

      typography: proseConfig(typographyConfig),
    },
  },

  safelist: [
    ...withBackgroundColorVariants([
      'cherry-blossom-50',
      'cherry-blossom-500',
      'yellow-sea-100',
      'yellow-sea-500',
      'brandy-50',
      'brandy-400',
      'emerald-100',
      'emerald-600',
      'cannon-pink-100',
      'cannon-pink-700',
      'eastern-blue-100',
      'eastern-blue-700',
      'orpiment-100',
      'orpiment-400',
      'elm-100',
      'elm-700',
      'clementine-100',
      'clementine-400',
      'cinnabar-100',
      'cinnabar-400',
      'sand-100',
      'sand-200',
      'gray-100',
    ]),
  ],

  plugins: [gridSystemPlugin(), typographyPlugin(typographyConfig)],
};
