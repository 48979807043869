/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "description_variant_default": "",
  "description_size_default": "text-headline-xs max-w-95 mt-3",
  "description_colors_default": "text-black",
  "row_variant_default": "flex justify-between flex-col sm:flex-row flex-wrap",
  "row_size_default": "gap-x-3",
  "row_colors_default": "",
  "column_variant_default": "",
  "column_size_default": "mb-6 first:w-full first:lg:w-1/3 [&:nth-child(n+2)]:max-w-60",
  "column_colors_default": "",
  "editors_variant_default": "mt-3",
  "editors_size_default": "text-headline-2xs",
  "editors_colors_default": "text-black",
  "list_title_variant_default": "",
  "list_title_size_default": "text-preamble mb-3",
  "list_title_colors_default": "text-black",
  "list_item_variant_default": "",
  "list_item_size_default": "text-headline-xs",
  "list_item_colors_default": "text-black",
  "list_variant_default": "flex flex-col",
  "list_size_default": "gap-y-3",
  "list_colors_default": "",
  "sites_title_variant_default": "text-center",
  "sites_title_size_default": "text-headline-2xs !font-bold mt-6 first-of-type:md:mt-12",
  "sites_title_colors_default": "text-black",
  "sites_item_variant_default": "underline",
  "sites_item_size_default": "text-headline-2xs",
  "sites_item_colors_default": "text-black",
  "sites_variant_default": "flex flex-wrap justify-center",
  "sites_size_default": "max-w-[720px] w-full gap-x-2.5 mx-auto",
  "sites_colors_default": "",
  "logo_variant_default": "relative",
  "logo_size_default": "max-w-30 w-full mb-1",
  "logo_colors_default": "text-black",
  "soMe": {},
  "soMe_headline_variant_default": "",
  "soMe_headline_size_default": "text-headline-2xs mt-6 w-full",
  "soMe_headline_colors_default": "text-black",
  "soMe_group_colors_default": "",
  "soMe_group_size_default": "gap-3",
  "soMe_group_variant_default": "flex flex-wrap",
  "copyright_variant_default": "text-center",
  "copyright_size_default": "text-headline-2xs max-w-[720px] w-full mt-6 mx-auto",
  "copyright_colors_default": "text-black",
  "variant_default": "relative wings",
  "size_default": "px-grid-m py-6 lg:py-9.5 max-w-grid mx-auto",
  "colors_default": "bg-cherry-blossom-200 wings-cherry-blossom-200"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Footer");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;