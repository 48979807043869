const cherryBlossom = {
  50: '#FDF2F3',
  100: '#F9DBDD',
  200: '#F3B8BD',
  300: '#E68B94',
  400: '#D96973',
  500: '#99474E',
};

const yellowSea = {
  100: '#FFFCBE',
  300: '#FFEA76',
  400: '#FEDE2A',
  500: '#FEB001',
  600: '#C08200',
};

const brandy = {
  50: '#FBF3F2',
  100: '#F5E3DB',
  200: '#F1D7CC',
  300: '#DFAF9B',
  400: '#AD8676',
};

const emerald = {
  100: '#DFF1E7',
  200: '#B5DBC2',
  300: '#81C8A0',
  500: '#4BAA76',
  600: '#38845B',
};

const cannonPink = {
  100: '#F0E4E9',
  200: '#E4C5D4',
  400: '#B67291',
  600: '#904165',
  700: '#602942',
};

const easternBlue = {
  100: '#D3EDF1',
  200: '#AADDE4',
  400: '#5DB8CA',
  600: '#1D99AF',
  700: '#106676',
};

const orpiment = {
  100: '#FFE9DA',
  200: '#FFCEA9',
  300: '#F6A671',
  400: '#F18E4E',
  600: '#A15C30',
};

const elm = {
  100: '#E2EFDF',
  200: '#B9D7B5',
  500: '#679F9F',
  700: '#197271',
  800: '#0E4848',
};

const clementine = {
  100: '#FFD9B2',
  200: '#FEC17F',
  300: '#FEA84D',
  400: '#FE901B',
  600: '#AA6112',
};

const cinnabar = {
  100: '#FAC8B8',
  200: '#F59C85',
  300: '#EF6D53',
  400: '#EA4D33',
  600: '#B73C28',
};

const sand = {
  50: '#FAF4EC',
  100: '#F2E9DD',
  200: '#E5DACD',
  300: '#D4B693',
  500: '#AB8863',
};

const success = {
  200: '#D5E3BC',
  400: '#B0D271',
  500: '#8DCA1C',
  700: '#638F10',
  800: '#385207',
};

const error = {
  200: '#F1C2B7',
  300: '#F17D62',
  500: '#FF3000',
  600: '#B11E00',
  800: '#630F00',
};

const gray = {
  50: '#F0F0F0',
  100: '#DDDDDD',
  300: '#BDBDBD',
  400: '#9E9E9E',
  500: '#757575',
  600: '#5F5F5F',
  700: '#3F3F3F',
  900: '#202020',
};

const colors = {
  'cherry-blossom': {
    DEFAULT: cherryBlossom[400],
    ...cherryBlossom,
  },
  'yellow-sea': {
    DEFAULT: yellowSea[300],
    ...yellowSea,
  },
  brandy: {
    DEFAULT: brandy[300],
    ...brandy,
  },
  emerald: {
    DEFAULT: emerald[500],
    ...emerald,
  },
  'cannon-pink': {
    DEFAULT: cannonPink[600],
    ...cannonPink,
  },
  'eastern-blue': {
    DEFAULT: easternBlue[600],
    ...easternBlue,
  },
  orpiment: {
    DEFAULT: orpiment[400],
    ...orpiment,
  },
  elm: {
    DEFAULT: elm[700],
    ...elm,
  },
  clementine: {
    DEFAULT: clementine[400],
    ...clementine,
  },
  cinnabar: {
    DEFAULT: cinnabar[400],
    ...cinnabar,
  },
  sand: {
    DEFAULT: sand[100],
    ...sand,
  },
  success: {
    DEFAULT: success[500],
    ...success,
  },
  error: {
    DEFAULT: error[500],
    ...error,
  },
  gray: {
    ...gray,
  },
};

module.exports = colors;
